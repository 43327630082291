import React from 'react'
import Decimal from 'decimal.js'

const isUrl = value => {
  if (!value.match(/^https?:\/\//)) {
    return false
  }

  try {
    /* eslint-disable no-new */
    new URL(value)
    return true
  } catch (e) {
    return false
  }
}

const formatThousands = value => {
  const [intPart, fractPart] = value.split('.')
  const thousandsSeperated = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formatted = thousandsSeperated + (fractPart ? '.' + fractPart : '')
  return formatted
}
const isNull = value => {
  return value === null || value === undefined
}

const formatNull = (alignment = 'tl') => {
  return <span className={`nowrap gray ttu tracked f6 ${alignment}`}>null</span>
}

const formatBoolean = value => {
  if (isNull(value)) {
    return formatNull()
  }

  return <span className="nowrap dark-gray code f6 tl">{String(value)}</span>
}

const formatNumber = (value, type, name) => {
  if (isNull(value)) {
    return formatNull('tr')
  }

  const isIdColumn = name.match(/^id$/i) || name.match(/_(id|fk|pk)$/i)
  const number = new Decimal(value).toFixed()

  let formatted = isIdColumn ? (
    value
  ) : (
    <React.Fragment>
      {formatThousands(number)
        .split(',')
        .map((c, idx) => (
          <span className="mr--2" key={idx}>
            {c}
          </span>
        ))}
    </React.Fragment>
  )
  return <span className="nowrap dark-gray code f6 tr">{formatted}</span>
}

const formatString = value => {
  if (isNull(value)) {
    return formatNull()
  }

  if (value === '') {
    return <span className="nowrap gray ttu tracked f6">empty</span>
  } else if (isUrl(value)) {
    return (
      <span className="nowrap dark-gray tl">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="blue"
          href={value}
        >
          {value}
        </a>
      </span>
    )
  } else {
    return <span className="lh-copy dark-gray tl">{value}</span>
  }
}

const formatTimestamp = (value, type) => {
  if (isNull(value)) {
    return formatNull()
  }

  const formatted = value
  return <span className="nowrap dark-gray code f6 tl">{formatted}</span>
}

const formatObject = (value, type) => {
  return (
    <span className="nowrap dark-gray f7 tl">
      <pre className="code mv0">{JSON.stringify(value)}</pre>
    </span>
  )
}

const format = (value, type, name) => {
  switch (type) {
    case 'text':
    case 'character varying':
    case 'unknown':
      return formatString(value)
    case 'boolean':
      return formatBoolean(value)
    case 'smallint':
    case 'integer':
    case 'bigint':
    case 'real':
    case 'double precision':
    case 'float':
    case 'numeric':
      return formatNumber(value, type, name)
    case 'date':
    case 'timestamp without time zone':
    case 'timestamp with time zone':
      return formatTimestamp(value, type)
    case 'hstore':
    case 'json':
      return formatObject(value, type)
    default:
      return isNull(value) ? formatNull() : formatString(value.toString())
  }
}

export default format
