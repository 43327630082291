import { compactInteger, intComma } from 'humanize-plus'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'

const compactIntRe = /([\d.,]+)(\D*)/i

export function bytesPrettifier(value, decimalPlaces = 0, zeroSuffix = 'B') {
  if (value < 1) {
    return ['0', zeroSuffix]
  }
  let format = '0'
  if (decimalPlaces !== 0) {
    format += '.' + '0'.repeat(decimalPlaces) // returns a decimalPlaces-length string of zeros
  }
  // This is a gross hack.
  // We want to format numbers using binary (not decimal, or SI) bytes, e.g. 1024 bytes to a k.
  // However, we don't want to use the correct suffix for these kinds of bytes: KiB, MiB, GiB, etc.
  // Numeral behaves correctly, if you want binary bytes, it'll use the proper suffix.
  // Hacky solution: strip out the 'i' if it exists.
  let [formatted, suffix] = numeral(value)
    .format(`${format} ib`)
    .split(' ')
  suffix = suffix.replace('i', '')
  return [formatted, suffix]
}

/**
 * Take a numeric value and make it pretty.
 * @param  {Number} value The number to be prettified.
 * @param  {Number} threshold = 0 A threshold below which to just intComma the number (e.g. 12,345). Zero to disable.
 * @param  {Number} digits    = 1 How many decimal places to display. Defaults to 1, e.g. 123.4k
 * @param  {Number} tinyThreshold = 0 If nonzero, values below this number are prettified as '< N', ''.
 * @return {[String, String]} An array containing the numeric string and the uppercased suffix like [num, suffix]
 */
export function numberPrettifier(
  value,
  threshold = 0,
  digits = 1,
  tinyThreshold = 0
) {
  if (!_.isFinite(value)) {
    value = 0
  }

  if (tinyThreshold && (value > 0 && value < tinyThreshold)) {
    return [`< ${intComma(tinyThreshold)}`, '']
  }

  if (value > 0 && value < 1) {
    // to avoid esoteric prefixes, if we have a value < 1e-9, we format it as zero
    const suffixes = ['m', 'µ', 'n']
    let suffixIdx = -1
    let num = value
    do {
      num *= 1000
      suffixIdx += 1
    } while (num < 1 && suffixIdx < suffixes.length - 1)
    return [intComma(num), suffixes[suffixIdx]]
  }

  if (threshold !== 0 && value > 1 && value < threshold) {
    return [intComma(value), '']
  }

  let [num, suffix] = compactIntRe.exec(compactInteger(value, digits)).slice(1)
  suffix = suffix.toUpperCase()
  return [num, suffix]
}

/**
 * Takes a rate (0..1) and returns a nicely-formatted percent
 * @param {number} value     = the value to format
 * @return {[String, String]} An array containing the numeric string and the uppercased suffix like [num, suffix]
 */
export function percentPrettifier(value) {
  return [numeral(value * 100).format('1.1'), '%']
}

/**
 * Take a duration and make it pretty
 * @param  {Number} value The duration to be prettified
 * @param  {String} units = 'milliseconds' the units of the value
 * @return {[Number, String]} An array containing the numeric string and the uppercased suffix like [num, suffix]
 */
export function durationPrettifier(value, units = 'milliseconds') {
  const duration = moment.duration(value, units)
  if (duration.asSeconds() < 1) {
    return [intComma(value), 'ms']
  } else if (duration.asMinutes() < 1) {
    return [compactInteger(duration.asSeconds(), 1), 'seconds']
  } else if (duration.asHours() < 1) {
    return [compactInteger(duration.asMinutes(), 1), 'minutes']
  } else if (duration.asDays() < 1) {
    return [compactInteger(duration.asHours(), 1), 'hours']
  } else if (duration.asWeeks() < 1) {
    return [compactInteger(duration.asDays(), 1), 'days']
  } else if (duration.asMonths() < 1) {
    return [compactInteger(duration.asWeeks(), 1), 'weeks']
  } else if (duration.asYears() < 1) {
    return [compactInteger(duration.asMonths(), 1), 'months']
  } else {
    return [compactInteger(duration.asYears(), 1), 'years']
  }
}
