import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

const DELAY_TIME = 60000

export default class RelativeTime extends React.Component {
  static propTypes = {
    date: PropTypes.string,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    placeholder: '–'
  }

  componentDidMount() {
    this._tid = setInterval(() => this.forceUpdate(), DELAY_TIME)
  }

  componentWillUnmount() {
    clearInterval(this._tid)
  }

  getRelativeTime() {
    let normalized
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}\w+/.test(this.props.date)) {
      normalized = this.props.date
    } else {
      const [date, time, timezone] = this.props.date.split(' ')
      normalized = `${date}T${time}${timezone.replace('UTC', 'Z')}`
    }
    return moment(normalized).fromNow()
  }

  content() {
    if (this.props.date) {
      return (
        <time
          title={this.props.date}
          dateTime={this.props.date}
          className="relativeTime"
        >
          {this.getRelativeTime()}
        </time>
      )
    } else {
      return (
        <span className="relative-time-placeholder">
          {this.props.placeholder}
        </span>
      )
    }
  }

  render() {
    return this.content()
  }
}
