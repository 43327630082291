import React from 'react'
import queryString from 'query-string'
import { useAsync } from 'react-async'
import { Redirect } from 'react-router-dom'

import LoadingState from 'client/components/LoadingState'

import { getBearerToken, DashboardStorage } from '../../utils'
import { getState } from '../../utils/oauth'

function AuthCallback({ location, dispatch }) {
  const { code, state } = queryString.parse(location.search)

  const { data, isLoading, error } = useAsync({
    promiseFn: getBearerToken,
    code
  })

  React.useEffect(() => {
    if (data) {
      DashboardStorage.setItem('dhc-session-user', JSON.stringify(data))
      dispatch({
        type: 'SET_USER',
        user: data
      })
    }
  }, [data, dispatch])

  const oldState = getState()

  if (!oldState || state !== oldState) {
    return <Redirect to="/" />
  }

  if (data) {
    return null
  }
  if (error) {
    return <Redirect to="/" />
  }
  if (isLoading) {
    return <LoadingState />
  }
}

export default AuthCallback
