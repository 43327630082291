import React from 'react'
import * as P from 'prop-types'
import { HKButton } from '@heroku/react-hk-components'
import { MalibuIcon } from '@heroku/react-malibu'
import { downloadResults } from 'client/helpers/dataclipDownload'

const DataclipDownloadButtons = ({ clipSlug }) => {
  const handleCsvDownload = () => {
    downloadResults(clipSlug, 'csv')
  }

  const handleJsonDownload = () => {
    downloadResults(clipSlug, 'json')
  }

  return (
    <div className="hk-button-group mr2">
      <HKButton type="secondary" onClick={handleCsvDownload}>
        <MalibuIcon
          name="direction-down-16"
          size={16}
          fillClass="fill-purple"
        />
        CSV
      </HKButton>
      <HKButton type="secondary" onClick={handleJsonDownload}>
        <MalibuIcon
          name="direction-down-16"
          size={16}
          fillClass="fill-purple"
        />
        JSON
      </HKButton>
    </div>
  )
}

DataclipDownloadButtons.propTypes = {
  clipSlug: P.string.isRequired
}

export default DataclipDownloadButtons
