import React, { Component } from 'react'
import PropTypes from 'prop-types'
import analytics from 'client/helpers/analytics'

class DataclipResultError extends Component {
  static propTypes = {
    errorMessage: PropTypes.string
  }

  componentDidMount() {
    analytics.track('Dataclip Results Error', {
      error: this.props.errorMessage
    })
  }

  render() {
    const { errorMessage } = this.props
    return (
      <div className="ba pa2 bg-lightest-red red b--light-red white ma4">
        <div className="f4 b">Dataclip Error</div>
        <div className="f5 mt1 overflow-auto">
          <pre>
            <code>{errorMessage}</code>
          </pre>
        </div>
      </div>
    )
  }
}

export default DataclipResultError
