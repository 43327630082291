import analytics from 'client/helpers/analytics'
import { getLocation } from 'client/helpers/getLocation'

export function downloadResults(slug, format) {
  const location = getLocation()

  analytics.track(`Dataclip Results ${format.toUpperCase()} Downloaded`)
  location.assign(buildResultsUrl(slug, format))
}

export function buildResultsUrl(slug, format) {
  const location = getLocation()
  return `${location.protocol}//${location.host}/dataclips/${slug}.${format}`
}
