/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { HKIcon, ProductIcons, Fills } from '@heroku/react-hk-components'

const UnexpectedError = () => {
  return (
    <div className="flex justify-center flex-column flex-auto ph2">
      <div className="flex flex-column items-center">
        <HKIcon
          name={ProductIcons.FailureRing28}
          size={64}
          className="mb3"
          fill={Fills.DarkGray}
        />
        <div className="f3 dark-gray mb2 fw6">Something went wrong</div>
        <p className="lh-copy dark-gray measure tc">
          If you&apos;re <span className="fw6">using an ad-blocker</span>,
          please try disabling it. You can also check the{' '}
          <a
            href="https://status.heroku.com"
            target="_blank"
            className="hk-link dark-gray fw6"
          >
            Heroku Status site
          </a>{' '}
          to see if there are any incidents impacting our service.
        </p>
        <div className="flex">
          <a
            href="https://help.heroku.com/"
            target="_blank"
            className="no-hover-underline flex items-center hk-button--secondary"
          >
            Visit Heroku Help
          </a>
        </div>
      </div>
    </div>
  )
}

export default UnexpectedError
