import React from 'react'
import { useUserState, useUserDispatch } from './context/user'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AuthWorkflow from 'client/components/AuthWorkflow'
import AuthCallback from 'client/components/AuthCallback'
import ClipUnauthenticatedContainer from 'client/components/Dataclip/ClipUnauthenticatedContainer'
import DHC from 'client/components/DHC'

function App() {
  const { user, impersonatedUser, preauthData } = useUserState()
  const dispatch = useUserDispatch()

  if (user) {
    return (
      <DHC
        user={user}
        impersonatedUser={impersonatedUser}
        preauthData={preauthData}
      />
    )
  } else {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/auth/heroku/callback"
            render={props => <AuthCallback {...props} dispatch={dispatch} />}
          />
          <Route
            exact
            path="/dataclips/:slug(\w{28})"
            component={ClipUnauthenticatedContainer}
          />
          <Route component={AuthWorkflow} />
        </Switch>
      </Router>
    )
  }
}

export default App
