import React from 'react'
import ClipResultCell from 'client/components/Dataclip/ClipResultCell'
import { Column, SortOrder } from 'react-base-table'
import { orderBy } from 'lodash'
import moment from 'moment'

const isNumeric = postgresType => {
  return [
    'smallint',
    'integer',
    'bigint',
    'real',
    'double precision',
    'numeric',
    'float'
  ].includes(postgresType)
}

const sortByTime = (key, order) => (a, b) => {
  let momentA = moment(a[key])
  let momentB = moment(b[key])
  if (order === SortOrder.ASC) {
    return momentA - momentB
  } else {
    return momentB - momentA
  }
}

const tableSortFunc = (data, sort) => {
  const { key, order, column } = sort
  const { columnType } = column

  if (customSortMap[columnType]) {
    return data.sort(customSortMap[columnType](key, order))
  } else {
    return orderBy(data, [key], [order])
  }
}

const customSortMap = {
  date: sortByTime,
  'timestamp with time zone': sortByTime,
  'timestamp without time zone': sortByTime
}

const buildTableData = data => {
  const types = data.get('type_names')
  const values = data.get('values')
  const fields = data.get('fields')
  const numCols = fields.size

  if (values.size === 0) {
    return [null, null]
  } else {
    const tableData = values.toJS().map((valArray, valIndex) => {
      return valArray.reduce(
        (acc, field, index) => {
          const compoundKey = `${fields.get(index)}-${index}`
          acc[compoundKey] = field
          return acc
        },
        {
          hkRowId: `row-${valIndex}`
        }
      )
    })

    const tableColumns = data.get('fields').map((title, fieldIdx) => {
      const key = `${title}-${fieldIdx}`
      const type = types.get(fieldIdx)
      const alignment = isNumeric(types.get(fieldIdx)) ? 'right' : 'left'
      const shouldGrow = numCols === 1 ? 0 : 1

      return (
        <Column
          key={key}
          dataKey={key}
          flexGrow={shouldGrow}
          headerClassName="hk-table-header"
          align={alignment}
          dataGetter={({ columnIndex, rowData }) => {
            const value = rowData[key]
            const type = types.get(columnIndex)
            return <ClipResultCell value={value} type={type} name={title} />
          }}
          resizable
          sortable
          columnType={type}
          title={title}
          minWidth={180}
          width={200}
        />
      )
    })
    return [tableData, tableColumns]
  }
}

function useResultTable(data) {
  // Refs & Component State
  let tableRef = React.useRef(null)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [sort, setSort] = React.useState({})

  // Callbacks
  const onPageChange = page => {
    setCurrentPage(page)
    if (tableRef.current) {
      tableRef.current.scrollToTop(0)
    }
  }

  const onSortChange = sortBy => {
    setSort(sortBy)
  }

  // Table Data
  const [tableData, tableColumns] = React.useMemo(() => buildTableData(data), [
    data
  ])
  const sortedTableData = sort.key ? tableSortFunc(tableData, sort) : tableData

  return {
    sort,
    onSortChange,
    currentPage,
    onPageChange,
    tableRef,
    sortedTableData,
    tableColumns
  }
}

export default useResultTable
