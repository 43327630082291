import React from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import DataclipDetachedError from 'client/components/DataclipDetachedError'
import DataclipLargeResults from 'client/components/DataclipLargeResults'
import DataclipResultError from 'client/components/DataclipResultError'
import DataclipResultSpinner from 'client/components/DataclipResultSpinner'
import ClipFetchResultsContainer from 'client/components/Dataclip/ClipFetchResultsContainer'

import { resultSizeLimit } from 'client/config'

export const ClipResultsContainer = ({ clip }) => {
  const slug = clip.get('slug')
  const detached = clip.get('detached')

  const addonName = clip.getIn(['datasource', 'addon_name'])
  const attachmentName = clip.getIn(['datasource', 'attachment_name'])
  const appName = clip.getIn(['datasource', 'app_name'])
  const resultSize = clip.getIn(['versions', 0, 'latest_result_size'], 0)

  const resultsTooLarge = resultSize > resultSizeLimit
  const executionError = clip.getIn(['versions', 0, 'result', 'error'])
  const lastKnownChecksum = clip.getIn([
    'versions',
    0,
    'latest_result_checksum'
  ])

  const executingQuery =
    !lastKnownChecksum && !executionError && !resultsTooLarge && !detached

  return (
    <React.Fragment>
      {detached && (
        <DataclipDetachedError
          addon={addonName}
          attachment={attachmentName}
          app={appName}
        />
      )}
      {resultsTooLarge && <DataclipLargeResults className="" dataclip={clip} />}
      {executionError && !detached && (
        <DataclipResultError errorMessage={executionError} />
      )}
      {lastKnownChecksum && !resultsTooLarge && !executionError && (
        <ClipFetchResultsContainer
          checksum={lastKnownChecksum}
          slug={slug}
          clip={clip}
        />
      )}
      {executingQuery && <DataclipResultSpinner message="Executing query..." />}
    </React.Fragment>
  )
}

ClipResultsContainer.propTypes = {
  clip: PropTypes.instanceOf(Immutable.Map)
}

export default React.memo(ClipResultsContainer)
