import React from 'react'
import { HKTablePagination } from '@heroku/react-hk-components'
import BaseTable from 'react-base-table'
import { chunk } from 'lodash'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import DataclipResultTableEmptyState from 'client/components/DataclipResultTableEmptyState'
import Spinner from 'client/components/Spinner'

import useResultTable from 'client/components/Dataclip/hooks/useResultTable'

const PAGINATION_THRESHOLD = 50

const LoadingOverlay = (
  <div className="bg-white-80 absolute top-0 left-0 right-0 bottom-0 h-100 w-100 flex items-center justify-center">
    <div className="flex flex-column items-center justify-center pe-none">
      <Spinner />
      <h1 className="tc mid-gray normal f4 mt2">Loading results...</h1>
    </div>
  </div>
)

const ClipResultTable = ({ loading, data, width, height }) => {
  const {
    sort,
    onSortChange,
    currentPage,
    onPageChange,
    tableRef,
    sortedTableData,
    tableColumns
  } = useResultTable(data)

  const numRows = sortedTableData ? sortedTableData.length : 0
  const showPagination = numRows > PAGINATION_THRESHOLD
  const tableHeight = showPagination ? height - 40 : height
  const chunked = React.useMemo(() =>
    chunk(sortedTableData, PAGINATION_THRESHOLD)
  )
  const visibleTableData = chunked[currentPage]

  React.useEffect(() => {
    // If page length changed between renders, let's reset back to 0th page
    if (chunked.length <= currentPage) {
      onPageChange(0)
    }
  }, [chunked])

  return (
    <React.Fragment>
      {!sortedTableData && <DataclipResultTableEmptyState />}
      {sortedTableData && (
        <React.Fragment>
          <BaseTable
            sortBy={sort}
            rowKey="hkRowId"
            rowHeight={40}
            headerHeight={40}
            className="hk-table with-shadow"
            onColumnSort={onSortChange}
            fixed
            data={visibleTableData}
            width={width}
            height={tableHeight}
            ref={tableRef}
            overlayRenderer={loading ? LoadingOverlay : null}
          >
            {tableColumns}
          </BaseTable>
          {showPagination && (
            <HKTablePagination
              page={currentPage}
              pages={chunked.length}
              onPageChange={onPageChange}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

ClipResultTable.propTypes = {
  data: PropTypes.instanceOf(Immutable.Map),
  height: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  width: PropTypes.number.isRequired
}

export default ClipResultTable
