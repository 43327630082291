import React from 'react'
import Async from 'react-async'
import * as P from 'prop-types'

import analytics from 'client/helpers/analytics'
import { initGlostick } from '../../../utils'
import DataclipHeader from 'client/components/DataclipHeader'
import DataclipResultError from 'client/components/DataclipResultError'
import ClipResultsContainer from 'client/components/Dataclip/ClipResultsContainer'
import { loadPublicClip } from 'client/components/Dataclip/util/loadPublicClip'

import AuthWorkflow from 'client/components/AuthWorkflow'

const ClipUnauthenticatedContainer = ({ match }) => {
  const { slug } = match.params

  React.useEffect(() => {
    initGlostick({ email: '' })
    analytics.track('Public Dataclip Page Viewed')
  }, [])

  return (
    <Async promiseFn={loadPublicClip} slug={slug}>
      <Async.Loading>
        <DataclipHeader loading isPublic />
      </Async.Loading>
      <Async.Fulfilled>
        {clip => (
          <React.Fragment>
            <DataclipHeader title={clip.get('title')} isPublic />
            <ClipResultsContainer clip={clip} />
          </React.Fragment>
        )}
      </Async.Fulfilled>
      <Async.Rejected>
        {error => {
          if (error.response && error.response.status === 404) {
            return <AuthWorkflow />
          } else {
            return (
              <DataclipResultError errorMessage="An unexpected error occurred" />
            )
          }
        }}
      </Async.Rejected>
    </Async>
  )
}

ClipUnauthenticatedContainer.propTypes = {
  match: P.object.isRequired
}

export default ClipUnauthenticatedContainer
