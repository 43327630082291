import React from 'react'
import produce from 'immer'
import * as P from 'prop-types'

import { DashboardStorage } from '../utils'

const UserStateContext = React.createContext()
const UserDispatchContext = React.createContext()

const initialState = {
  user: null,
  impersonatedUser: null
}

function userReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'SET_USER':
        draft.user = action.user
        break
      default:
        break
    }
  })
}
function UserProvider({ children }) {
  const cachedUser = DashboardStorage.getItem('dhc-session-user')
  const cachedImpersonatedUser = DashboardStorage.getSessionItem('hk-sudo-data')
  const cachedPreauthData = DashboardStorage.getSessionItem('hk-preauth-data')

  const [state, setUser] = React.useReducer(userReducer, {
    user: cachedUser ? JSON.parse(cachedUser) : null,
    impersonatedUser: cachedImpersonatedUser
      ? JSON.parse(cachedImpersonatedUser)
      : null,
    preauthData: cachedPreauthData ? JSON.parse(cachedPreauthData) : null
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={setUser}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

UserProvider.propTypes = {
  children: P.element.isRequired
}

function useUserState() {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}
function useUserDispatch() {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUserState, useUserDispatch }
