import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Immutable from 'immutable'
import { HKButton } from '@heroku/react-hk-components'
import ContainerDimensions from 'react-container-dimensions'

import analytics from 'client/helpers/analytics'
import { numberPrettifier, durationPrettifier } from 'client/lib/prettifiers'
import getVisualizationProps from 'client/helpers/getVisualizationProps'
import DataclipDownloadButtons from 'client/components/DataclipDownloadButtons'
import DataclipVisualization from 'client/components/DataclipVisualization'
import ClipResultTable from 'client/components/Dataclip/ClipResultTable'

const ClipResultsDataContainer = ({
  clip,
  data,
  loading,
  hasNewResults,
  onLoadNewResults
}) => {
  const [tab, setTab] = React.useState('table') // or 'chart'

  const handleShowTable = () => {
    setTab('table')
  }

  const handleShowChart = () => {
    setTab('chart')
  }

  const latestVersion = clip.getIn(['versions', 0])
  const resultMetadata = latestVersion.get('result')
  const duration = resultMetadata.get('duration')

  // X rows in Y ms
  const [rows, rowsSuffix] = numberPrettifier(data.get('values').size)
  const lastRanAt = moment(latestVersion.get('latest_result_at')).format(
    'MMMM D, YYYY HH:mm'
  )
  const [prettyDuration, durationSuffix] = durationPrettifier(duration)

  React.useEffect(() => {
    analytics.track('Dataclip Results Loaded', {
      duration: prettyDuration
    })
  }, [prettyDuration])

  const meta = `${rows}${rowsSuffix} rows in ${prettyDuration} ${durationSuffix}`

  const slug = clip.get('slug')
  const chartData = React.useMemo(() => getVisualizationProps(data.toJS()), [
    data
  ])

  return (
    <div className="result-wrap flex-1 flex flex-column relative">
      <div className="pa3">
        <div className="flex-l items-center justify-between">
          <div className="flex mb2 mb0-l">
            <div className="mr3">
              <div className="gray ttu tracked f7 mt0 mb1">Results</div>
              <div className="mv0">{meta}</div>
            </div>
            <div>
              <div className="gray ttu tracked f7 mt0 mb1">Updated</div>
              <div className="mv0">{lastRanAt}</div>
            </div>
          </div>
          {hasNewResults && (
            <div className="mt3 mt0-l">
              <HKButton onClick={onLoadNewResults} small type="success">
                Load New Results
              </HKButton>
            </div>
          )}
          <div className="flex items-center mt3 mt0-l">
            <div className="flex nowrap">
              <DataclipDownloadButtons clipSlug={slug} />
              <div className="hk-button-group">
                <HKButton
                  onClick={handleShowTable}
                  type={tab === 'table' ? 'primary' : 'secondary'}
                >
                  Table
                </HKButton>
                <HKButton
                  onClick={handleShowChart}
                  type={tab === 'chart' ? 'primary' : 'secondary'}
                >
                  Chart
                </HKButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 right-0 bottom-0 mh3 mb3 dataclip-result-wrap">
          <ContainerDimensions>
            {size => (
              <React.Fragment>
                {tab === 'chart' && chartData && (
                  <DataclipVisualization {...size} {...chartData} />
                )}
                {tab === 'chart' && !chartData && (
                  <div className="hk-well flex-1 items-center justify-center">
                    <div className="f4 mb2 lh-title">
                      Unable to generate a chart.
                    </div>
                    <div className="f5 mw7 center lh-copy">
                      Between 10 – 1000 rows of time series data are needed to
                      show a chart.
                    </div>
                  </div>
                )}
                {tab === 'table' && (
                  <ClipResultTable loading={loading} {...size} data={data} />
                )}
              </React.Fragment>
            )}
          </ContainerDimensions>
        </div>
      </div>
    </div>
  )
}

ClipResultsDataContainer.propTypes = {
  clip: PropTypes.instanceOf(Immutable.Map),
  data: PropTypes.instanceOf(Immutable.Map),
  hasNewResults: PropTypes.bool,
  loading: PropTypes.bool,
  onLoadNewResults: PropTypes.func
}

export default ClipResultsDataContainer
