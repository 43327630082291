import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'client/components/Spinner'

const DataclipResultSpinner = ({ message }) => (
  <div className="w-100 h5 flex flex-column items-center justify-center">
    <Spinner />
    <h1 className="tc mid-gray normal f4 mt2">{message}</h1>
  </div>
)

DataclipResultSpinner.propTypes = {
  message: PropTypes.string
}

export default DataclipResultSpinner
