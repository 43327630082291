import { DashboardStorage, env } from '../../utils'
import { generateState } from '../../utils/oauth'

function AuthWorkflow() {
  const cachedReturnTo = DashboardStorage.getSessionItem('hk-return-to')
  if (!cachedReturnTo) {
    const returnTo = `${window.location.pathname}${window.location.search}${window.location.hash}`
    DashboardStorage.setSessionItem('hk-return-to', returnTo)
  }

  const callbackURL = `${window.location.origin}/auth/heroku/callback`
  const state = generateState()
  const params = {
    redirect_uri: callbackURL,
    state
  }

  const encodedParams = Object.entries(params)
    .map(([key, value]) => {
      return `${key}=${encodeURIComponent(value)}`
    })
    .join('&')

  const url = `${env.REACT_APP_FRONT_END_AUTH_URL}/login-dhc?${encodedParams}`

  window.location.assign(url)
  return null
}

export default AuthWorkflow
