import PropTypes from 'prop-types'

import { format } from 'client/components/Dataclip/util'

const ClipResultCell = ({ name, type, value }) => {
  return format(value, type, name)
}

ClipResultCell.propTypes = {
  name: PropTypes.string,
  type: PropTypes.any,
  value: PropTypes.any
}

export default ClipResultCell
