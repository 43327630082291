/**
 * The contents of this file...
 *
 * (•_•)
 * ( •_•)>⌐■-■
 * (⌐■_■)
 *
 * ...may surprise you.
 *
 * In some environments, localStorage is either unavailable or partially working.
 * Private Browsing mode in Safari, for example, does not allow you to write
 * or read keys from localStorage.
 *
 * In those environments, we want to be able to write to an in-memory database
 * instead of localStorage.
 *
 * `DashboardStorage` provides a consistent API (based on the localStorage API)
 * that will persist to disk when `localStorage` is available, or use an in-memory
 * database when it is not.
 *
 * We chose to create this interface vs monkey-patching the localStorage
 * prototype because other things like Ember Simple Auth have their own detections
 * for determining whether to use localStorage or their own fallback.
 *
 * Either way, as long as you are using `DashboardStorage`, your code will work
 * in both environments.
 *
 * Caveats:
 *
 * Data saved to DashboardStorage using the in-memory store will be gone when
 * you refresh the page.
 *
 */

/* eslint-disable no-restricted-globals */

let DashboardStorage
const LOCAL_STORAGE_TEST_KEY = '__data-dashboard_test_key__'

// localStorage
try {
  localStorage.setItem(LOCAL_STORAGE_TEST_KEY, true)
  localStorage.removeItem(LOCAL_STORAGE_TEST_KEY)
  DashboardStorage = useBrowserStorage()
} catch (err) {
  DashboardStorage = memoryStorage()
}

function useBrowserStorage() {
  return {
    setItem(key, value) {
      return localStorage.setItem(key, value)
    },
    getItem(key) {
      return localStorage.getItem(key)
    },
    removeItem(key) {
      return localStorage.removeItem(key)
    },
    clear() {
      return localStorage.clear()
    },

    setSessionItem(key, value) {
      return sessionStorage.setItem(key, value)
    },
    getSessionItem(key) {
      return sessionStorage.getItem(key)
    },
    removeSessionItem(key) {
      return sessionStorage.removeItem(key)
    },
    clearSession() {
      return sessionStorage.clear()
    }
  }
}

function memoryStorage() {
  const _localStorage = {}
  const _sessionStorage = {}

  return {
    setItem(key, value) {
      const val = (_localStorage[key] = value)
      return val
    },
    getItem(key) {
      // avoid returning undefined
      if (!_localStorage.hasOwnProperty(key)) {
        return null
      }
      return _localStorage[key]
    },
    removeItem(key) {
      delete _localStorage[key]
    },
    clear() {
      for (const key in _localStorage) {
        if (_localStorage.hasOwnProperty(key)) {
          delete _localStorage[key]
        }
      }
    },

    setSessionItem(key, value) {
      const val = (_sessionStorage[key] = value)
      return val
    },
    getSessionItem(key) {
      // avoid returning undefined
      if (!_sessionStorage.hasOwnProperty(key)) {
        return null
      }
      return _sessionStorage[key]
    },
    removeSessionItem(key) {
      delete _sessionStorage[key]
    },
    clearSession() {
      for (const key in _sessionStorage) {
        if (_sessionStorage.hasOwnProperty(key)) {
          delete _sessionStorage[key]
        }
      }
    }
  }
}

export default DashboardStorage
