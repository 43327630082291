import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Immutable from 'immutable'
import { useAsync } from 'react-async'

import { usePrevious } from 'client/components/Dataclip/hooks/usePrevious'
import DataclipResultSpinner from 'client/components/DataclipResultSpinner'
import DataclipResultError from 'client/components/DataclipResultError'
import ClipResultsDataContainer from 'client/components/Dataclip/ClipResultsDataContainer'

const fetchResults = async slug => {
  try {
    const res = await axios.get(`/dataclips/${slug}.json`, {
      headers: {
        'X-Heroku-Data-Tab': sessionStorage.getItem('herokudataTabId'),
        accept: 'application/json'
      }
    })
    return Immutable.fromJS(res.data)
  } catch (e) {
    throw new Error('Error fetching dataclip results.')
  }
}

const ClipFetchResultsContainer = ({ clip, checksum, slug }) => {
  const [hasNewResults, setHasNewResults] = React.useState(false)
  const { data, error, isLoading, run } = useAsync({
    deferFn: fetchResults,
    slug
  })
  const prevChecksum = usePrevious(checksum)

  React.useEffect(() => {
    run(slug)
  }, [])

  React.useEffect(() => {
    if (prevChecksum && prevChecksum !== checksum) {
      setHasNewResults(true)
    }
  }, [checksum, prevChecksum])

  const handleLoadNewResults = () => {
    run(slug)
    setHasNewResults(false)
  }

  return (
    <React.Fragment>
      {data && (
        <ClipResultsDataContainer
          hasNewResults={hasNewResults}
          onLoadNewResults={handleLoadNewResults}
          loading={isLoading}
          clip={clip}
          data={data}
        />
      )}

      {/* Initial loading spinner, assuming we dont have results on screen yet */}
      {!data && isLoading && (
        <DataclipResultSpinner message="Loading results..." />
      )}

      {error && (
        <DataclipResultError errorMessage="Unable to fetch results. Please refresh your browser and try again." />
      )}
    </React.Fragment>
  )
}

ClipFetchResultsContainer.propTypes = {
  checksum: PropTypes.string.isRequired,
  clip: PropTypes.instanceOf(Immutable.Map),
  slug: PropTypes.string.isRequired
}

export default ClipFetchResultsContainer
