import React from 'react'
import { HKIcon, ProductIcons, Fills } from '@heroku/react-hk-components'

function LoadingState() {
  return (
    <div className="vh-100 flex items-center justify-center">
      <div className="flex flex-column items-center">
        <HKIcon name={ProductIcons.LogoBlock28} size={64} fill={Fills.Silver} />
        <HKIcon
          className="mt2"
          name={ProductIcons.Loading28}
          size={28}
          fill={Fills.Silver}
        />
      </div>
    </div>
  )
}

export default LoadingState
