import React from 'react'
import { useAsync } from 'react-async'

import LoadingState from 'client/components/LoadingState'
import UnexpectedError from 'client/components/UnexpectedError'
import { DashboardStorage } from '../../utils'
import BootstrapClient from 'client/components/BootstrapClient'
import { AuthService } from '../../services'

const ClientEntryPoint = React.lazy(() => import('client/Root'))

const whoAmI = async ({ token }) => {
  try {
    const res = await AuthService.whoAmI(token)
    return res.data
  } catch (e) {
    throw e
  }
}

const DHC = ({ user, impersonatedUser, preauthData }) => {
  const { data, error, isLoading } = useAsync({
    promiseFn: whoAmI,
    token: user.access_token
  })
  const accessToken = user.access_token
  const refreshToken = user.refresh_token

  React.useEffect(() => {
    const returnTo = DashboardStorage.getSessionItem('hk-return-to')
    if (returnTo) {
      DashboardStorage.removeSessionItem('hk-return-to')
      window.location.assign(returnTo)
    }
  }, [])

  React.useEffect(() => {
    if (error && error.response && error.response.status === 401) {
      DashboardStorage.clear()
      window.location.reload()
    }
  }, [error])

  if (data) {
    const { options, service, user } = data
    const userWithTokens = {
      ...user,
      accessToken,
      refreshToken
    }
    return (
      <React.Suspense fallback={<LoadingState />}>
        <BootstrapClient
          service={service}
          user={userWithTokens}
          options={options}
          impersonatedUser={impersonatedUser}
          preauthData={preauthData}
        >
          <ClientEntryPoint
            user={user}
            impersonatedUser={impersonatedUser}
            preauthData={preauthData}
          />
        </BootstrapClient>
      </React.Suspense>
    )
  }

  if (error) {
    const malformed = !error.response
    const unexpected = error.response && error.response.status != 401
    if (malformed || unexpected) {
      return <UnexpectedError />
    }
    return null
  }
  if (isLoading) {
    return <LoadingState />
  }
}

export default DHC
