import PropTypes from 'prop-types'

import format from './formatCell'

const ClipShape = {
  sql: PropTypes.string,
  title: PropTypes.string,
  attachmentId: PropTypes.string
}

const FormikPropTypes = {
  values: PropTypes.shape(ClipShape),
  errors: PropTypes.shape(ClipShape),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired
}

export { format, FormikPropTypes }
