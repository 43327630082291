import React from 'react'
import PropTypes from 'prop-types'
import { MalibuIcon } from '@heroku/react-malibu'

const DataclipDetachedError = ({ addon, attachment, app }) => (
  <div className="ba pa2 bg-lightest-red red b--light-red white ma4">
    <div className="f3 b flex items-center mb2">
      <MalibuIcon
        name={'error-badge-28'}
        size={24}
        fillClass="red"
        extraClasses="mr2"
      />{' '}
      {'Your dataclip is detached'}
    </div>
    <div>
      <p>
        The datastore <code className="b">{addon}</code> no longer exists. (
        <code className="b">{attachment}</code> on{' '}
        <code className="b">{app}</code>){' '}
      </p>
      <p>
        Please use the datastore picker above to choose a new target for this
        dataclip, then click “Save &amp; Run”.
      </p>
    </div>
  </div>
)

DataclipDetachedError.propTypes = {
  addon: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  attachment: PropTypes.string.isRequired
}

export default DataclipDetachedError
