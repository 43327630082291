import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import DataclipDownloadButtons from 'client/components/DataclipDownloadButtons'

const DataclipLargeResults = ({ className = 'overflow-scroll', dataclip }) => {
  const slug = dataclip.get('slug')

  return (
    <div className={className}>
      <div className="flex flex-column flex-auto items-center justify-center pv5">
        <h1 className="dark-gray f1">Clip Results</h1>
        <p className="tc dark-gray normal f4">
          This result is too large to display.
        </p>
        <ul className="list w8 pl0">
          <DataclipDownloadButtons clipSlug={slug} />
        </ul>
      </div>
    </div>
  )
}

DataclipLargeResults.propTypes = {
  className: PropTypes.string,
  dataclip: PropTypes.instanceOf(Immutable.Map).isRequired
}

export default DataclipLargeResults
