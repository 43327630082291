import { DashboardStorage } from '.'

const CHALLENGE_SIZE = 32

export function genRandomString(byteSize) {
  const Crypto = window.crypto || window.msCrypto
  const array = new Uint8Array(byteSize)
  Crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join('')
}

function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0')
}

const STATE_NAME = 'heroku-oauth-state'

export function generateState() {
  DashboardStorage.setSessionItem(STATE_NAME, genRandomString(CHALLENGE_SIZE))
  return getState()
}

export function getState() {
  return DashboardStorage.getSessionItem(STATE_NAME)
}
